<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="addItem">New</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="flights"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.departure_date_time="{ item }">
        {{ formatDateAndTime(item.departure_date_time) }}
      </template>
      <template v-slot:item.arrival_date_time="{ item }">
        {{ formatDateAndTime(item.arrival_date_time) }}
      </template>
      <template v-slot:item.subgroups="{ item }">
        <span v-for="(subgroup, index) in item.subgroups" :key="subgroup.id" @click.stop>
          <router-link :to="`/subgroups/${subgroup.id}`" class="base-link">{{ subgroup.title }}</router-link>
          <span v-if="index != item.subgroups.length - 1">, </span>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog width="640" :fullscreen="$vuetify.breakpoint.xsOnly" v-model="showFlightManageDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Flight Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="showFlightManageDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <FlightDetailForm
            :group_id="group_id"
            :flight_id="flight_id"
            :isvisible="showFlightManageDialog"
            @closed="onFlightMangageDialogClosed"
          />
        </v-card>
      </template>
    </v-dialog>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import FlightDetailForm from '@/views/FlightDetailForm';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'GroupFlightList',
  components: {
    FlightDetailForm,
    ConfirmDialog
  },
  mixins: [dateMixin],
  methods: {
    addItem: function () {
      this.flight_id = 'new';
      this.showFlightManageDialog = true;
    },
    editItem(item) {
      this.flight_id = item.id;
      this.showFlightManageDialog = true;
    },
    deleteItem(item) {
      this.flight_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('flight/delete', this.flight_id);
      this.load();
    },
    onFlightMangageDialogClosed() {
      this.showFlightManageDialog = false;
      this.load();
    },
    load() {
      if (this.group_id) {
        store.dispatch('flight/list', {
          page: 1,
          group_id: this.group_id,
          per_page: 100,
          sort_by: 'departure_date_time',
          sort_desc: false
        });
      }
    }
  },
  mounted() {
    this.load();
  },
  props: ['group_id'],
  watch: {
    group_id: function () {
      this.load();
    }
  },
  computed: {
    ...mapGetters('flight', ['loading', 'error', 'flights', 'meta'])
  },
  data: () => ({
    showFlightManageDialog: false,
    showDeleteConfirmDialog: false,
    flight_id: null,
    headers: [
      { text: 'Departs', value: 'departure_date_time' },
      { text: 'Departs From', value: 'origin' },
      { text: 'Flight', value: 'flight_number' },
      { text: 'Airline', value: 'airline' },
      { text: 'Arrives At', value: 'destination' },
      { text: 'Arrives', value: 'arrival_date_time' },
      { text: 'Sub Group', value: 'subgroups' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
