import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":"","color":"toolbar-action transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},[_vm._v("New")])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.flights,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.departure_date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.departure_date_time))+" ")]}},{key:"item.arrival_date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTime(item.arrival_date_time))+" ")]}},{key:"item.subgroups",fn:function(ref){
var item = ref.item;
return _vm._l((item.subgroups),function(subgroup,index){return _c('span',{key:subgroup.id,on:{"click":function($event){$event.stopPropagation();}}},[_c('router-link',{staticClass:"base-link",attrs:{"to":("/subgroups/" + (subgroup.id))}},[_vm._v(_vm._s(subgroup.title))]),(index != item.subgroups.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c(VDialog,{attrs:{"width":"640","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showFlightManageDialog),callback:function ($$v) {_vm.showFlightManageDialog=$$v},expression:"showFlightManageDialog"}},[[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("Flight Details")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showFlightManageDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('FlightDetailForm',{attrs:{"group_id":_vm.group_id,"flight_id":_vm.flight_id,"isvisible":_vm.showFlightManageDialog},on:{"closed":_vm.onFlightMangageDialogClosed}})],1)]],2),_c('ConfirmDialog',{attrs:{"title":"Confirm delete","description":"Are you sure you want to delete this item","okayLabel":"Yes","cancelLabel":"No","show":_vm.showDeleteConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }