import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Airline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.airlines,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Airline","clearable":""},model:{value:(_vm.flightLocal.airline_id),callback:function ($$v) {_vm.$set(_vm.flightLocal, "airline_id", $$v)},expression:"flightLocal.airline_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Flight Number","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Flight #","error-messages":errors},model:{value:(_vm.flightLocal.flight_number),callback:function ($$v) {_vm.$set(_vm.flightLocal, "flight_number", $$v)},expression:"flightLocal.flight_number"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Origin","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Origin","error-messages":errors},model:{value:(_vm.flightLocal.origin),callback:function ($$v) {_vm.$set(_vm.flightLocal, "origin", $$v)},expression:"flightLocal.origin"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Destination","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Destination","error-messages":errors},model:{value:(_vm.flightLocal.destination),callback:function ($$v) {_vm.$set(_vm.flightLocal, "destination", $$v)},expression:"flightLocal.destination"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Departure Date","rules":"required|date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Departure Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.departure_date),callback:function ($$v) {_vm.departure_date=$$v},expression:"departure_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Departure Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('TimeInput',{attrs:{"disabled":_vm.loading,"label":"Departure time","error-messages":errors},model:{value:(_vm.departure_time),callback:function ($$v) {_vm.departure_time=$$v},expression:"departure_time"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Arrival Date","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Arrival Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.arrival_date),callback:function ($$v) {_vm.arrival_date=$$v},expression:"arrival_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Arrival Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('TimeInput',{attrs:{"disabled":_vm.loading,"label":"Arrival time","error-messages":errors},model:{value:(_vm.arrival_time),callback:function ($$v) {_vm.arrival_time=$$v},expression:"arrival_time"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Type","clearable":""},model:{value:(_vm.flightLocal.flight_types_id),callback:function ($$v) {_vm.$set(_vm.flightLocal, "flight_types_id", $$v)},expression:"flightLocal.flight_types_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Subgroup","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.subgroups,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Subgroup","multiple":"","clearable":""},model:{value:(_vm.flightLocal.subgroups),callback:function ($$v) {_vm.$set(_vm.flightLocal, "subgroups", $$v)},expression:"flightLocal.subgroups"}})}}],null,true)})],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }