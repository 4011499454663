<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Airline" rules="required">
              <v-select
                v-model="flightLocal.airline_id"
                :disabled="loading"
                :items="lists.airlines"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Airline"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Flight Number" rules="required|min:2">
              <v-text-field
                v-model="flightLocal.flight_number"
                :disabled="loading"
                label="Flight #"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Origin" rules="required|min:2">
              <v-text-field
                v-model="flightLocal.origin"
                :disabled="loading"
                label="Origin"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Destination" rules="required|min:2">
              <v-text-field
                v-model="flightLocal.destination"
                :disabled="loading"
                label="Destination"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Departure Date" rules="required|date">
              <DateInput
                v-model="departure_date"
                label="Departure Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Departure Time" rules="required">
              <TimeInput
                v-model="departure_time"
                :disabled="loading"
                label="Departure time"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Arrival Date" rules="date">
              <DateInput
                v-model="arrival_date"
                label="Arrival Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Arrival Time" rules="required">
              <TimeInput
                v-model="arrival_time"
                :disabled="loading"
                label="Arrival time"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="flightLocal.flight_types_id"
                :disabled="loading"
                :items="lists.types"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Type"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Subgroup" rules="required">
              <v-select
                v-model="flightLocal.subgroups"
                :disabled="loading"
                :items="lists.subgroups"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Subgroup"
                multiple
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { date } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import DateInput from '@/views/DateInput';
import TimeInput from '@/views/TimeInput';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'FlightDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput,
    TimeInput
  },
  props: ['group_id', 'subgroup_id', 'flight_id', 'isvisible'],
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('flight', ['loading', 'flight', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        airlines: [],
        types: [],
        subgroups: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    flightLocal: function () {
      const flight = loadDash.cloneDeep(this.flight);
      if (this.flight_id == 'new') {
        flight.flight_types_id = 1;
      }
      return flight;
    }
  },
  methods: {
    /**
     * Get the date portion of the string
     * @param dateTime string
     * @param time time portion if true, otherwise returns date portion if false
     * @returns string
     */
    formatDateTime(dateTime, time) {
      if (!dateTime) return '';
      return dateTime.split(' ')[time ? 1 : 0];
    },
    /**
     * Load the flight
     */
    load: function () {
      this.resetValidation();
      store.dispatch('flight/get', { id: this.flight_id, group_id: this.group_id });
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        this.flightLocal.departure_date_time = this.departure_date + ' ' + this.departure_time;
        this.flightLocal.arrival_date_time = this.arrival_date + ' ' + this.arrival_time;
        await store.dispatch('flight/save', {
          id: this.flightLocal.id,
          flight: this.flightLocal
        });
      }

      this.$emit('closed');
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    }
  },
  mounted: async function () {
    this.load();
  },
  watch: {
    flight: function () {
      this.departure_date = this.formatDateTime(this.flight.departure_date_time);
      this.arrival_date = this.formatDateTime(this.flight.arrival_date_time);
      this.departure_time = this.formatDateTime(this.flight.departure_date_time, true);
      this.arrival_time = this.formatDateTime(this.flight.arrival_date_time, true);
    },
    lists: function () {
      // Pre-select all the subgroups by default
      if (this.flight_id == 'new') {
        this.flightLocal.subgroups = this.lists.subgroups.map(x => x.id);
      }
    },
    isvisible: function (newValue) {
      if (newValue) {
        this.load();
      }
    }
  },
  data: () => ({
    departure_date: '',
    arrival_date: '',
    departure_time: '',
    arrival_time: ''
  })
};
</script>
