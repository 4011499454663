<template>
  <v-text-field
    v-model="timeFormatted"
    :disabled="disabled"
    :label="label"
    maxlength="5"
    hint="HH:mm format"
    persistent-hint
    :error-messages="errorMessages"
    @keypress="keyPressHandler"
    @change="inputHandler($event)"
    clearable
  ></v-text-field>
</template>

<script>
export default {
  name: 'TimeInput',
  props: ['value', 'label', 'disabled', 'error-messages'],
  data: () => ({
    time: null,
    timeFormatted: null
  }),
  methods: {
    keyPressHandler(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9*:+]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    inputHandler() {
      this.time = this.parseTime(this.formatTime(this.timeFormatted));
      this.$emit('input', this.time);
    },
    split(str, index) {
      const result = [str.slice(0, index), str.slice(index)];
      return result;
    },
    parseTime(time) {
      if (!time) return '';
      if (!time.match(/^([01]\d|2[0-3]):([0-5]\d)$/)) return time;
      const [hour, minute] = time.split(':');
      return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00`;
    },
    formatTime(time) {
      if (time.length == 2 && time.indexOf(':') == -1 && parseInt(time, 10) >= 0) {
        time = time + ':' + '00';
      }

      if (time.length == 4 && time.indexOf(':') == -1 && parseInt(time, 10) >= 0) {
        const [first, second] = this.split(time, 2);
        time = first + ':' + second;
      }

      if (!time) return '';
      if (time.indexOf(':') == -1) return '';
      let [hour, minute] = time.split(':');

      if (isNaN(parseInt(hour, 10)) || isNaN(parseInt(minute, 10))) {
        return '';
      }

      if (parseInt(hour, 10) > 24) {
        hour = 24;
      }

      if (parseInt(minute, 10) > 59) {
        minute = 59;
      }

      return `${hour}:${minute}`;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal != null) {
          this.time = newVal;
          this.timeFormatted = this.formatTime(this.time);
        }
      }
    }
  }
};
</script>
